import { Controller } from '@hotwired/stimulus';
import { get } from "@rails/request.js"

let debounce = require("lodash.debounce");

export default class extends Controller {
  static targets = [ "hidden", "results", "wrapper", "selection", "input" ];
  static values = {
    schoolId: Number,
    schoolName: String
  }

  initialize(){
    this.search = debounce(this.search, 300).bind(this);
  }

  connect() {
    if ( this.schoolIdValue && this.schoolNameValue ){
      this.setSchool(this.schoolIdValue, this.schoolNameValue);
    }
  }

  search(e) {
    get(e.target.dataset.url, {
      responseKind: "turbo-stream",
      query: { query: e.target.value }
    }).then(() => { this.resultsTarget.classList.remove('hidden') });
  }

  select(e) {
    const schoolId = parseInt(e.target.dataset.schoolId);
    const schoolName = e.target.dataset.schoolName;
    this.setSchool(schoolId, schoolName);
  }

  setSchool(schoolId, schoolName){
    this.hiddenTarget.value = schoolId;
    this.selectionTarget.innerHTML = schoolName;
    this.wrapperTarget.classList.remove('hidden');
    this.inputTarget.classList.add('hidden');
    this.resultsTarget.classList.add('hidden');
  }

  reset(e){
    this.hiddenTarget.value = "";
    this.selectionTarget.innerHTML = "";
    this.wrapperTarget.classList.add('hidden');
    this.inputTarget.classList.remove('hidden');
    this.resultsTarget.classList.remove('hidden');
    this.inputTarget.focus();
  }

  blur(e){
    setTimeout(() => {
      this.resultsTarget.classList.add('hidden');
    }, 200);
  }

  focus(e){
    if ( this.inputTarget.value.length > 2 ){
      this.search(e);
    }
  }
}
