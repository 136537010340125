import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import YouTubePlayer from "youtube-player"

export default class extends Controller {
  static values = {
    id: Number,
    identifier: String
  }

  static targets = ["stage"];

  initialize() {
    this.current = {};
    this.lastStopTime = 0;
    this.element['youtube'] = this;
  }

  connect() {
    const player = YouTubePlayer(this.stageTarget, {
      width: '100%',
      height: '100%',
    });

    player.on('ready', e => {
      this.youtube = e.target;
    });

    player.on('stateChange', e => {
      let time= e.target.playerInfo.currentTime;

      // PLAYING
      if ( e.data === 1 ) {
        if ( Math.abs(this.lastStopTime - time) < 0.5 ){
          time = this.lastStopTime;
        }

        this.current = { video_id: this.idValue, start_time: this.toTenth(time) };
      }

      // PAUSED
      if ( e.data === 2 ){
        this.current.end_time = this.toTenth(time);
        this.lastStopTime = time;
        this.fireEvent();
      }

      // ENDED
      if ( e.data === 0 ){
        this.current.end_time = this.toTenth(time);
        this.lastStopTime = time;
        this.fireEvent();
      }
    });

    player.loadVideoById({ 'videoId': this.identifierValue });
  }

  get player () {
    return this.youtube;
  }

  toTenth(time) {
    return parseFloat(time.toFixed(1));
  }

  fireEvent() {
    post(document.body.dataset.eventsUrl, {
      responseKind: 'json',
      body: this.current
    });
  }
}
